/* import tailwind */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* import fonts */

@import url("./assets/styles/fonts.css");

/* import prims css */

/* general rules */

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 16px;  
  @apply w-screen;
  @apply h-screen;  
  @apply font-inter;
  @apply font-normal;
  @apply text-white;
}

body {
  @apply w-full;
  @apply h-full;
  @apply bg-dark;
}

body div#root {
  @apply w-full;
  @apply h-full;
  overflow: auto;
}

.tooltip {
  max-width: 15rem;
  background: #303338 !important;
  border-radius: 4px !important;
  padding: 0.625rem !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0; 
}

.skeleton-loading {
  width: 100%;
  height: 100%;
  background-color: rgba(217, 217, 217, 0.48);
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.skeleton-loading::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(90deg, rgba(217,217,217,0) 0%, rgba(217,217,217,0.24) 49%, rgba(217,217,217,0) 100%);
  transform: translateX(-100%);
  animation: shimmer 2s infinite;
}

.syntax-higlighter > pre::before {
  content: '';
  position: absolute;
  width: 4.5rem;
  height: 100%;
  background: #000;
  z-index: -1;
  top: 0;
  left: 0;
}

pre:has(div.codeblock) {
  padding: 0;
}
