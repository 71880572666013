.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 2rem;
}
  
.inputWrapper label {
  font-weight: 500;
  line-height: 140%;
  text-transform: capitalize;
}

.inputWrapper input {
  border: 1px solid #c1e2ff80;
  border-radius: 4px;
  outline: none;
  padding: 12px;
  background: transparent;
  color: #f9f9f9;
  opacity: 0.7;
}

.inputWrapper input:focus {
  border: 1px solid #c1e2ff;
  opacity: 0.9;
}

.tooltip {
  max-width: 15rem;
  background: #303338 !important;
  border-radius: 4px !important;
  padding: 0.625rem !important;
}

label > span {
  color: #c1e2ff;
  vertical-align: middle;
}