/* Inter family */

@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-Medium.ttf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-SemiBold.ttf");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-Bold.ttf");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

/* Sora family */

@font-face {
  font-family: "Sora";
  src: url("../fonts/sora/Sora-Light.ttf");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Sora";
  src: url("../fonts/sora/Sora-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Sora";
  src: url("../fonts/sora/Sora-SemiBold.ttf");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

/* Courrier family */

@font-face {
  font-family: "Courrier";
  src: url("../fonts/courrier/CourierPrime-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("../fonts/robotoMono/RobotoMono-Light.ttf");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}