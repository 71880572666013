.cursor {
  display: inline-block;
  width: 9px;
  height: 18px;
  background-color: rgb(249,249,249);
  animation: blink 1s infinite;
  margin-left: 2px;
}

@keyframes blink {
  0%, 49% {
      opacity: 1;
  }
  50%, 100% {
      opacity: 0;
  }
}
