.msgContainer {
  padding: 2.5rem 1.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.msgContainer > svg {
  margin-bottom: 1rem;
}

.msgTitle {
  font-family: "Sora", sans-serif;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 140%;
  margin-bottom: 1rem;
}

.msgDesc {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 140%;
  margin-bottom: 1rem;
  max-width: 314px;
  text-align: center;
}

.connectBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 0.9375rem;
  gap: 0.625rem;
  color: #f9f9f9;
  margin-bottom: 0.5rem;
}
.connectBtn > span {
  font-weight: 600;
  font-size: 0.875remß;
  line-height: 140%;
}

.link {
  position: relative;
  display: inline-block;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 140%;
  color: #27ae60;
}

.link:hover {
  color: #27ae60;
}

.link::before {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 1px;
  background: #27ae60;
}
