.repoItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.875rem;
  background: #10203A;
  border-bottom: 1px solid #34506F;
}

.repoItem .repoName {
  display: flex;
  align-items: center;
}

.repoItem .repoName > span {
  font-size: 1.125rem;
  line-height: 140%;
  color: #C1E2FF;
  margin-left: 0.5rem;
  text-transform: capitalize;
}

.repoMeta > span {
  font-size: 0.875rem;
  line-height: 140%;
  color: #C1E2FF;
  margin-right: 2rem;
}

.importBtn {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 140%;
  padding: 0.3125rem 0.875rem;
}

.spin {
  animation: spinner-border .75s linear infinite;
  color: #C1E2FF;
}

@keyframes spinner-border {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}