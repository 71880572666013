
.container {
    margin: 0 auto;
    margin-top: 4.375rem;
    width: 840px;
}

.header {
    position: relative;
    min-height: 59px;
    display: flex;
    flex-direction: row;
    align-items: center;
    
}



.header::before {
    content: "";
    position: absolute;
    width: 24px;
    height: 3px;
    left: 0px;
    bottom: 0;
    background: linear-gradient(90deg, #208AEC -8.33%, #27AE60 100%);
    box-shadow: 0px 0px 7px rgba(193, 226, 255, 0.16);
    border-radius: 4px;
}


.headerText {
    display: inline-block;
    margin-left: 10px;
    font-family: "Sora", sans-serif;
    font-weight: 400;
    font-size: 1.875rem;
    line-height: 130%;
    text-shadow: 1px 1px 22px rgba(14, 27, 49, 0.7), 1px 1px 46px rgba(193, 226, 255, 0.48);
}

.headerDesc {
    margin: 20px 0;
    font-weight: 400;
    font-size: 1rem;
    line-height: 140%;
    opacity: 0.7;
}

.newContainerWrapper {
    width: 100%;
    border: 1px solid #34506F;
    box-shadow: 0px 0px 7px rgba(193, 226, 255, 0.16);
    border-radius: 10px;
    min-height: 400px;
    margin-bottom: 5rem;
}

.newContainerHeader {
    min-height: 4.875rem;
    border-bottom: 1px solid #34506F;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
}
.headerLeftContent {
    display: flex;
    align-items: center;
}
.headerLeftContent > div {
    margin-left: 10px;
    font-family: "Sora", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
}

.headerRightContentInput {
    display: flex;
    min-width: 40%;
    align-items: center; 
    justify-content: space-between;
    border-radius: 4px;
    border: 1px solid #c1e2ff6d;
    padding: 8px 12px;
}

.headerRightContentInput .searchInput {
    border: none;
    outline: none;
    color: #F9F9F9;
    opacity: 0.7;
    background-color: #0F1C32;
    width: 100%;
}

.headerRightContentInput .searchInput:disabled,
.headerRightContentInput.disabled .searchInput {
    opacity: 0.2;
    cursor: not-allowed;
    pointer-events: none;
}
.headerRightContentInput .searchInput:focus {
    opacity: 1;
}

.headerRightContentInput > svg {
    color: #F9F9F9;
}

.headerRightContentInput.disabled > svg {
    color: #f9f9f952;
}

.username {
    font-family: "Inter", sans-serif;
    font-weight: 700;
}

.headerRightContent {
    display: flex;
    min-width: 40%;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    gap: 0.3125rem;
}

.selectedRepoInfo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.selectedRepoInfo > svg.left {
    margin-right: 0.5rem;
}

.selectedRepoInfo > svg.right {
    margin-left: 0.5rem;
}

.selectedRepoText {
    line-height: 140%;
    color: #C1E2FF;
}

.selectedRepoBranch {
    font-size: 0.875rem;
    color: #C1E2FF;
}

.backIcon {
    cursor: pointer;
}

.backIcon.disabled {
    cursor: default;
}

.spin {
    animation: spinner-border .75s linear infinite;
    color: #C1E2FF;
}

.widget_title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px;
    gap: 10px;

    width: 985px;
    height: 38px;


    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}





@keyframes spinner-border {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}