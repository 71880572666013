.inputFields {
  padding: 2.5rem 4.375rem;
  background: #10203A;
  border-bottom: 1px solid #34506F;
}

.inputSection {
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #c1e2ff33;
  margin: 1rem 0;
}


.inputSection .inputSectionTitle {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 140%;
  color: #CCCCCC;
  margin-bottom: 1.5rem;
  cursor: pointer;
}

.inputGroupDiv {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.625rem;
}

.groupInputAddBtn {
  display: inline-flex;
  align-items: center;
  gap: 0.625rem;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 140%;
  color: #27AE60;
}

.groupInputAddBtn:hover {
  color: #27AE60;
}

.actionBtnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 0;
  gap: 1rem;
}

.createBtn {
  padding: 0.75rem 0.875rem;
  font-weight: 700;
}

.cancelBtn {
  padding: 0.7rem 1.5rem;
  background: transparent;
  border: 1px solid #F9F9F9;
  font-weight: 700;
  color: #f9f9f9;
  opacity: .6;
}

.spin {
  animation: spinner-border .75s linear infinite;
  color: #C1E2FF;
}

@keyframes spinner-border {
  100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}