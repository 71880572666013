.labelText {
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  color: #F9F9F9;
  margin-bottom: 0.5rem;
}

.descText {
  font-size: 0.875rem;
  line-height: 140%;
  color: #FFFFFF;
  margin-bottom: 1rem;
}

.toggleSwitch {
  position: relative;
  display: inline-block;
  width: 86px;
  height: 28px;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #C1E2FF;
  cursor: pointer;
  z-index: 1;
}

.toggleSwitch input[type="checkbox"] {
  visibility: hidden;
}

.toggleSwitch .statusText {
  position: absolute;
  width: 50%;
  height: 100%;
  font-weight: 600;
  line-height: 140%;
  color: #f9f9f9;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
}

.toggleSwitch > span.checkedText {
  left: 0;
  top: 0;
}

.toggleSwitch > span.unCheckedText {
  bottom: 0;
  right: 0;
}

.toggleSwitch > span.knob {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  transform: translateX(100%);
  transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
  background-color: #27AE60;
  z-index: 1;
}


.toggleSwitch > input:checked + span.knob {
  transform: translateX(0);
}