.button {
    padding: 5px 14px;
    border-radius: 5px;
    cursor: pointer;
    color: #F9F9F9;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 140%;
    transition: all 0.5s ease-in-out;
}

.primary {
    background-color: #27AE60!important;
}

.primary:hover {
    filter: drop-shadow(1px 1px 10px #92E1B4);
    background-color: #13A39A!important;
    /* font-weight: bold; */
}

.primary:active, .primary:focus {
    background-color: #19875F!important;
}

.iconBtn, .secondary {
    border: 1px solid #F9F9F9;
    background-color: transparent;
}

.iconBtn:hover, .secondary:hover {
    background: rgba(255, 255, 255, 0.12);
    font-weight: 600;
}

.iconBtn:active, .iconBtn:focus {
    background: rgba(111, 207, 151, 0.11);
    border: 1px solid #219653!important;
}
.iconBtn:active, .iconBtn:focus > span {
    color: #219653 !important;
}
.iconBtn:focus, .iconBtn:active > span * svg {
    /* fill: #219653 !important; */
    color: #219653 !important;
}

.secondary:active, .secondary:focus {
    background: rgba(111, 207, 151, 0.11);
    border: 1px solid #19875F!important;
}
.secondary:active, .secondary:focus > span {
    color: #19875F !important;
}

.default:hover > span {
    color: #27AE60 !important;
    font-weight: 700;
}
.default:focus, .default:active {
    font-weight: 700;
}
.disabled {
    cursor: not-allowed;
    pointer-events: none;
}